import React, { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { Layout } from "./layouts/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/ConfigureStore";
import Spinner from "./components/spinner/Fallback-spinner";
import "./index.scss";
import "./assets/app.css";
import mock from "../src/layouts/components/mock/mock";
import "../src/components/autoComplete/AutoCompleteComponent";
import "../src/layouts/components/navbar/NavSearch";

mock.onAny().passThrough();

const LazyApp = lazy(() => import("./App"));

// Find the root element
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <Layout>
                <LazyApp />
            </Layout>
        </Suspense>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
