// super admin routes
export const SUPER_ADMIN_ROUTES = {
  DASHBOARD: "/SuperAdmin/dashboard",

  MANAGEUSER: "/SuperAdmin/manage-user/listing",
  DEACTIVATED_USER: '/SuperAdmin/deactivated-user/listing',
  ADD_USER: "/SuperAdmin/manage-user/add-user",
  EDIT_USER: "/SuperAdmin/manage-user/edit-user",
  EDIT_USER_ID: "/SuperAdmin/manage-user/edit-user/:userId",
  VIEW_USER: "/SuperAdmin/manage-user/view-user",
  VIEW_USER_ID: "/SuperAdmin/manage-user/view-user/:userId",

  SCHOOL_REQUEST_LISTING: "/SuperAdmin/school-request/new-school",
  SCHOOL_REQUEST_PAYMENT: "/SuperAdmin/school-request/payments",
  SCHOOL_REQUEST_PENDING: "/SuperAdmin/school-request/pending",
  SCHOOL_REQUEST_REJECTED: "/SuperAdmin/school-request/rejected",
  STUDENT_REGISTRATION_FORM: "/SuperAdmin/school-request/from",

  SCHOOL_LISTING: "/SuperAdmin/school/listing",
  SCHOOL_DEACTIVATED_LISTING: "/SuperAdmin/school/deactivated-listing",
  SCHOOL_ADD: "/SuperAdmin/school/add",

  SCHOOL_EDIT: "/SuperAdmin/school/edit",
  SCHOOL_EDIT_ID: "/SuperAdmin/school/edit/:schoolId",

  SCHOOL_VIEW: "/SuperAdmin/school/view",
  SCHOOL_VIEW_ID: "/SuperAdmin/school/view/:schoolId",

  SCHOOL_RENEW_SUBSCRIPTION: "/SuperAdmin/renew-subscription",
  SCHOOL_MESSAGE_ALLOTMENT: "/SuperAdmin/message-allotment/:schoolId",
  SCHOOL_BIOMETRIC_DEVICE: "/SuperAdmin/biometric-device/:schoolId",

  COMMUNICATION: "/SuperAdmin/communication",
  SMS_EMAIL: "/SuperAdmin/sms-email",


  REPORT: "/SuperAdmin/report",
  REPORT_PAYMENT: "/SuperAdmin/report-payment",
  REPORT_CREDIT: "/SuperAdmin/report-credit",
  REPORT_BIO: "/SuperAdmin/report-biometric",

  DOCUMENTS_UPLOAD: "/SuperAdmin/documents-bulk-upload",
  BULK_UPLOAD: "/SuperAdmin/bulk-upload",
  SCHOOL_BULK_TEACHER_UPLOAD: "/SuperAdmin/bulk-upload/teacher-upload",
  SCHOOL_BULK_STUDENT_UPLOAD: "/SuperAdmin/bulk-upload/student-upload",
  SCHOOL_BULK_ADMISSION_UPLOAD: "/SuperAdmin/bulk-upload/admission-upload",
  SCHOOL_BULK_DOCUMENT_UPLOAD: "/SuperAdmin/bulk-upload/document-upload",
};

// admin routes

export const ADMIN_ROUTES = {
  DASHBOARD: "/Admin/dashboard",
  ADMIN_MANAGE_SCHOOL: "/Admin/manage-school",
  MANAGE_REPORT: "/Admin/manage-report",
  EXPEND_DATA: "/Admin/expendData",
  COMMUNICATION: "/Admin/communication",
  COMMUNICATION_SETTING: "/Admin/communication-setting",
}



export const SUB_ADMIN_ROUTES = {
  DASHBOARD: "/dashboard",

  MANAGEUSER: "/manage-user/listing",
  ADD_USER: "/manage-user/add",
  SUBADMIN_DEACTIVATED_USER: "/manage-user/deactivate",
  EDIT_USER: "/manage-user/edit",
  VIEW_USER: "/manage-user/view",

  SCHOOL_INFO: "/school/info",
  SCHOOL_SETTING: "/school/settings",
  SCHOOL_ADD_CLASS: "/school/settings/class",
  SCHOOL_ADD_SECTION: "/school/settings/section",
  SCHOOL_ADD_SESSION: "/school/settings/session",
  SCHOOL_ADD_SUBJECT: "/school/settings/subject",
  SCHOOL_ADD_STREAM: "/school/settings/stream",
  SCHOOL_ADD_FEE_CATEGORY: "/school/settings/fee-category",
  SCHOOL_ADD_CASTE_CATEGORY: "/school/settings/caste-category",
  SCHOOL_ADD_HOUSE: "/school/settings/house",
  SCHOOL_ADD_FEE_GEN_SETTING: "/school/settings/fee-generation-setting",
  SCHOOL_ADD_ATTENDANCE: "/school/settings/add-attendance",
  SCHOOL_BULK_FEE_TRANSPORT: "/school/settings/bulk-fee-transport",
  SCHOOL_BULK_CARRY_FORWARD: "/school/settings/bulk-carry-forward",
  SCHOOL_ADMISSION_REG_FEE: "/school/settings/admission-registration-fee",
  SCHOOL_FEE_CONFIGURE: "/school/settings/configure-fee",
  SCHOOL_PENALITY: "/school/settings/discount-penality",
  SCHOOL_INCOME_SETTING: "/school/settings/income-setting",
  SCHOOL_EXPENDITURE_SETTING: "/school/setting/expenditure-setting",
  SCHOOL_STUDENT_BULK_UPLOAD_SETTING: "/school/settings/bulk-student-setting",
  SCHOOL_TEACHER_BULK_UPLOAD_SETTING: "/school/setting/bulk_teacher-setting",
  SCHOOL_FEE_CORRECTION:"/school/setting/fee_correction",
  SCHOOL_ADD_ADDITIONAL_SUBJECT :"/school/settings/add_additional_subject",
  SCHOOL_PAYMENT_CORRECTION:"/school/setting/payment_correction",
  SCHOOL_REVERT_CORRECTION:"/school/setting/revert_correction",
  SCHOOL_PAYMENT_CANCEL_HISTORY:"/school/setting/payment_cancel_history",
  SCHOOL_ADD_ADDITIONAL_SUBJECT :"/school/settings/add_additional_subject",

  // ADMISSION_SETTING: "/admission/setting",
  SCHEDULE_ENTRANCE_EXAM: "/admission/ScheduleEntranceExam",
  REGISTER_VIEW: "/admission/RegisterStudentView",
  REGISTER_STUDENT: "/admission/student",
  ADMISSION_ACCESS: "/admission/access",
  // PASSED_STUDENT: "/admission/passed",
  // FAILED_STUDENT: "admission/failed",
  ADD_RESULT: "/admission/result",
  VIEW_RESULT: "/admission/view",
  PAYMENT_COMPONENT: "/admission/paymentComponent",
  REGISTRATION_REPORT: "/admission/registrationReport",
  STUDENT_PAYMENT: "/admission/payment",



  STUDENT_LISTING: "/student/listing",
  STUDENT_ADD: "/student/add",
  STUDENT_DEACTIVATED: "/student/deactivated",
  STUDENT_VIEW: "/student/view",
  STUDENT_EDIT: "/student/edit",

  TEACHER_LISTING: "/teacher/listing",
  TEACHER_ADD: "/teacher/add",
  TEACHER_EDIT: "/teacher/edit",
  TEACHER_VIEW: "/teacher/view",
  TEACHER_DEACTIVATED: "/teacher/deactivate",

  TEACHER_ATTENDANCE_ADD: "/teacher-attendance/add",
  TEACHER_ATTENDANCE_VIEW: "/teacher-attendance/view",
  TEACHER_ATTENDANCE_TAKE: "/teacher-attendance/take",

  CLASS_MANAGE_SECTION: "/class/manage-section",
  CLASS_DELEGATE_TEACHER: "/class/delegate-teacher",
  CLASS_PROMOTE_STUDENT: "/class/promote-student",
  CLASS_VIEW_CLASSES: "/class/classes",
  CLASS_VIEW_INFO: "/class/class-info",
  VIEW_CLASS_STUDENT: "/class/class-student",

  STUDENT_ATTENDANCE_ADD: "/student-attendance/add",
  STUDENT_ATTENDANCE_DAILY_REPORT: "/student-attendance/daily-report",
  STUDENT_ATTENDANCE_CLASS_REPORT: "/student-attendance/class-report",
  STUDENT_ATTENDANCE_STUDENT_REPORT: "/student-attendance/student-report",
  STUDENT_ATTENDANCE_SHORT_TERM_ATTENDANCE:
    "/student-attendance/short-term-attendance",

  TIME_TABLE_SETTING: "/time-table/setting",
  TIME_TABLE_VIEW: "/time-table/view",

  COMMUNICATION: "/communication",
  COMMUNICATION_MESSAGE: "/communication/message",
  COMMUNICATION_EMPLOYEE: "/communication/employee",
  COMMUNICATION_HISTORY: "/communication/history",
  COMMUNICATION_NOTICE_BOARD: "/communication/notice-board",
  COMMUNICATION_TRANSPORT: "/communication/transport",
  COMMUNICATION_NOTIFICATION: "/communication/notification",
  COMMUNICATION_REPORT: "/communication/report",

  TRANSPORT_DRIVER_ADD: "/transport/driver/add",
  TRANSPORT_DRIVER_LISTING: "/transport/driver/listing",
  TRANSPORT_FUEL_CONSUMPTION: "/transport/fuel-consumption",
  TRANSPORT_ROUTE_ADD: "/transport/route/add",
  TRANSPORT_ROUTE_VIEW: "/transport/route/view",
  TRANSPORT_STOPS_LISTING: "/transport/stops/listing",
  TRANSPORT_STOPS_ADD: "/transport/stops/add",
  TRANSPORT_INVOICE_GENERATE: "/transport/invoice/generate",
  TRANSPORT_REPORT_SETTING: "/transport/report/setting",
  TRANSPORT_LIVE_TRACKING: "/transport/live/tracking",
  TRANSPORT_VEHICLE_LISTING: "/transport/vehicle/listing",
  TRANSPORT_VEHICLE_ADD: "/transport/vehicle/add",
  TRANSPORT: "/transport",
  TRANSPORT_SETTING: "/transport/setting",
  TRANSPORT_ROUTE_STOP_MAPPING: "/transport/route/mapping",
  TRANSPORT_USER_PROFILE: '/transport/user/view',

  FEE_PAYMENT: "/fee/payment",
  FEE_REPORT: "/fee/report",

  CALENDAR_HOLIDAY: "/calendar/holiday",

  STUDENT_SYLLABUS: "/student-syllabus",

  STUDENT_DIARY: "/student-diary",

  HOMEWORK: "/homework",

  CERTIFICATES: "/certificates",

  GALLERY: "/gallery",

  MISCELLANEOUS: "/misc",
  INCOME: "/income",
  EXPENDITURE: "/expenditure",

  EXAM: "/exam",
  EXAM_CARD: '/card',
  EXAM_SETTING: "/setting",
  TERM_MARKS:"/termMarks_allocation",
  TEST_MARKS:"/testMarks_allocation",
  SECTION_MARKS:"/sectionMarks_allocation",
  EXAM_MARKS:"/examMarks_allocation",
  EXAM_PATTERN: "/pattern",
  EXAM_SCHEDULE: "/schedule",
  EXAM_ALLOCATION: "/mark",
  EXAM_MARKSHEET:"/markSheet",
  EXAM_PUBLISH: "/publish",
  EXAM_REPORT: "/report",
  EXAM_SUPPLEMENTARY: "/supplementary",
  SUBJECT_RAMARK: "/remark",
  EXAM_INFO: "/infomation",
  NON_EXAM:"/nonExam",
  STUDENT_REMARKS:"/studentRemarks",
  ADD_ATTENDANCE_MARK:"/addAttendanceMark",
  CROSS_LIST:"/crossList",
  EXAM_CORRECTION:"/exam_Correction",


  LEAVE_MANAGEMENT: "/leaveManagement",
  LEAVE_MANAGEMENT_REPORT: "/leaveManagement-report"

};

export const RouteIds = {
  SCHOOL_ID: "schoolId",
  USER_ID: "userId",
  TEACHER_ID: "teacherId",
  STUDENT_ID: "studentId",
  ADMISSION_ID: "admissionId",
  CLASS_ID: "classId",
  ROUTE_ID: "routeId",
  TRANSPORT_USER_ID: "transportUserId",
  SECTION_ID: "sectionId",
  RESETLINK: 'resetLink'
}


export const PERMISSION_TYPE = {
  "create": 'C',
  "update": "U",
  "read": "R",
  "delete": "D",
}
