import {
    LOGIN_SUCCESS,
    LOG_OUT,
    USER_PROFILE,
    FETCH_USER_PROFILE
} from '../ActionTypes';
import API from '../../components/API';

// export const userSignIn = async (mobile, password, dispatch) => {
export const userSignIn = (token) => dispatch => {
    try {
        // let body = {
        //     mobileNumber: mobile,
        //     password: password
        // };
        // API.loginUser(body)
        //     .then(res => res.json())
        //     .then(jsonRes => {
        //         if (jsonRes.success) {
        //             const decodeResponse = decode(jsonRes.data.token);
        //             localStorage.setItem('token', jsonRes.data.token)
        //             localStorage.setItem('userRole', decodeResponse.role)
        //             localStorage.setItem('userPermission', JSON.stringify(jsonRes.data.permissions))
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: token,
                        // token: token
                    });
        //             setTimeout(() => {
        //                 window.location.reload();
        //             }, 50);
        //             // return jsonRes;
        //         } else {
        //             alert(jsonRes.errors.message);
        //             return jsonRes;
        //         }
        //     })
        //     .catch(err => {
        //         console.log("Error=> ", err);
        //         return err;
        //     })
    }
    catch (error) {
        console.log("Catch_Error=> ", error);
    }
};

export const getUserInfo = ()=> dispatch => {
    dispatch({
        type: FETCH_USER_PROFILE
    });
    API.getUserInfo()
        .then(res => res.json())
        .then(jsonRes => {
            if (jsonRes.success) {
                const payload = {
                    user: jsonRes.data,
                    role: jsonRes.data.role
                }
                dispatch({
                    type: USER_PROFILE,
                    payload: payload
                });
            } else {
                if(jsonRes.error)
                // alert(jsonRes.error.message);
                console.log(jsonRes);            }
        });
};

export const logoutUser = () => {
    localStorage.clear();
    sessionStorage.clear();
    // setTimeout(() => {
    //     window.location.replace('/');
    //     window.location.reload();
    // }, 50);    
    return {
        type: LOG_OUT,
    };
};

