import {
    ADD_ADDITIONAL_SUBJECT, ADD_ASSIGN_ADDITIONAL_SUBJECT, CLOSE_ALERT, CREATE_MARK_ALLOCATION, CROSS_LIST_DATA, DELETE_ADDITIONAL_SUBJECT,
    DELETE_ASSIGN_ADDITIONAL_SUBJECT, DELETE_EXAM_SCHEDULE, DELETE_EXAM_TIMETABLE_LIST, EDIT_TERM_ATTENDANCE_LIST, EXAM_ATTENDANCE_LIST, EXAM_TIMETABLE_POST,
    FETCH_ALL_SUBJECT_MARK_ALLOCATION, FETCH_CLASS_SUBJECT_LIST_FAILURE, FETCH_CLASS_SUBJECT_LIST_REQUEST,
    FETCH_CLASS_SUBJECT_LIST_SUCCESS, FETCH_EXAM_REPORT_DATA, FETCH_EXAM_SCHEDULE, FETCH_STUDENT_REMARK, GET_ADDITIONAL_SUBJECT,
    GET_ADMIT_CARD, GET_ASSIGN_ADDITIONAL_SUBJECT, GET_CLASS_SECTION_EXAM, GET_CLASS_SECTION_MAPPING_EXAM_SCHEDULE, GET_DESK_SLIP,
    GET_EXAM_ON_BASIS_TERM, GET_EXAM_PATTERN_ON_BASIS_SESSION, GET_EXAM_REPORT_DATA_LIST, GET_EXAM_SCHEDULE, GET_EXAM_SCHEDULE_VIEW,
    GET_EXAM_TIMETABLE_LIST, GET_EXAMTIMETABLE_CLASS, GET_EXAMTIMETABLE_SECTION, GET_MAPPING_STUDENT_REMARK_LIST,
    GET_MARK_ALLOCATION_EXAM, GET_MARK_ALLOCATION_STUDENT, GET_MARK_ALLOCATION_SUB_EXAM, GET_MARK_ALLOCATION_SUBJECT,
    GET_MARK_ALLOCATION_TERM, GET_MARKS_CLASS_VISE, GET_NON_EXAM_LIST, GET_SECTION_REPORT_DATA_LIST, GET_SUB_EXAM_ON_BASIS_EXAM, GET_SUB_EXAM_SCHEDULE,
    GET_SUBJECT_NON_EXAM, GET_SUBJECT_TIME_TABLE_LIST, GET_TERM_EXAM_TIMETABLE, GET_TERM_ON_BASIS_EXAM_PATTERN,
    GET_TERM_REPORT_DATA_LIST, INCOME_CLASS_MAPPING_LISTS, INCOME_CLASS_MAPPING_POST, INCOME_CLASS_SECTION_STUDENT, INCOME_CLASS_STUDENT, INCOME_HEADS_LISTS, INCOME_STUDENT_PAYMENT, INCOME_STUDENT_REPORT, MARKSHEET_DATA_LIST, POST_MARKS_CLASS_VISE, SET_ALERT, SET_EXAM_DATA, SET_EXAM_PATTERN, SET_SUB_EXAM_DATA, SET_TERM_DATA,
    TEMPLATE_LIST_CLASS, TEMPLATE_MAPPING_LIST, TEMPLATE_SETTING_LIST, TEMPLATE_VIEW, TERM_ATTENDANCE_LIST, VIEW_EXAM_TIMETABLE_LIST
} from "../../ActionTypes";

const initialState = {
    ExamPattern: [],
    termData: [],
    examData: [],
    subExamData: [], incomeHeadList: [], incomeStudentPayment: [],incomeStudentList:[],
    examPatternSchedule: [], incomeClassSectionList: [], incomeStudentReport: [],
    fetchexamScheduleList: [], sectionReport: [],
    subExamScheduleList: [], editTermAttendanceList: [], termMarksheetList: [],
    termSchedule: [], studentRemarkListMapping: [], CrossListData: [],
    examSchedule: [], examReportData: [], studentRemarkList: [],
    subExamSchedule: [], termReportList: [],
    classSection: [], templateSetting: [], examReportList: [],
    examScheduleList: [], templateMappingList: [], templateView: [],
    additionalSubject: [], nonExamSubject: [], templateListClass: [],
    assignAdditionalSubjectList: [], markAllocationTerm: [],
    classSectionExamList: [], nonExamList: [], classTimeTable: [],
    alert: {
        show: false,
        msg: "",
        type: "",
        title: ""
    },
    subjectExamList: [],
    examView: [], allSubjectMarkAllocation: [], termExamTimeTable: [], sectionTimeTable: [],
    admitCard: [], optionalSubjectData: [], uniqueOptionalSubjects: {}, uniqueOptionalType: [],
    deskSlip: [], markAllocationList: [], termAttendanceList: [], studentAttendanceList: [],
    ExamTimeTable: [], markAllocationStudent: [], markAllocation: [],
    ExamTimeTableView: [], markAllocationSubject: [], createMarkNonExam: []
};

const ExamReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXAM_PATTERN:
            return {
                ...state,
                ExamPattern: action.payload,
            };
        case SET_TERM_DATA:
            return {
                ...state,
                termData: action.payload,
            };
        case SET_EXAM_DATA:
            return {
                ...state,
                examData: action.payload,
            };
        case SET_SUB_EXAM_DATA:
            return {
                ...state,
                subExamData: action.payload,
            };
        case GET_EXAM_PATTERN_ON_BASIS_SESSION:
            return {
                ...state,
                examPatternSchedule: action.payload,
            };
        case SET_ALERT:
            return {
                ...state,
                alert: action.payload
            };
        case CLOSE_ALERT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    show: false
                }
            };
        case GET_TERM_ON_BASIS_EXAM_PATTERN:
            return {
                ...state,
                termSchedule: action.payload,
            };
        case GET_EXAM_ON_BASIS_TERM:
            return {
                ...state,
                examSchedule: action.payload,
            };
        case GET_SUB_EXAM_ON_BASIS_EXAM:
            return {
                ...state,
                subExamSchedule: action.payload,
            };
        case GET_EXAMTIMETABLE_CLASS:
            return {
                ...state,
                classTimeTable: action.payload
            }
        case GET_CLASS_SECTION_MAPPING_EXAM_SCHEDULE:
            return {
                ...state,
                classSection: action.payload,
            };
        case GET_EXAM_SCHEDULE:
            return {
                ...state,
                examScheduleList: action.payload,
            };
        case DELETE_EXAM_SCHEDULE:
            return {
                ...state,
                examScheduleList: state.examScheduleList.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case DELETE_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: state.additionalSubject.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case ADD_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: [...state.additionalSubject, ...action.payload]
            };
        case GET_ADDITIONAL_SUBJECT:
            return {
                ...state,
                additionalSubject: action.payload,
            };
        case DELETE_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: state.assignAdditionalSubjectList.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case ADD_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: [...state.assignAdditionalSubjectList, ...action.payload]
            };
        case GET_ASSIGN_ADDITIONAL_SUBJECT:
            return {
                ...state,
                assignAdditionalSubjectList: action.payload,
            };
        case GET_CLASS_SECTION_EXAM:
            return {
                ...state,
                classSectionExamList: action.payload,
            };
        case GET_SUBJECT_TIME_TABLE_LIST:
            return {
                ...state,
                subjectExamList: action.payload,
            };
        case GET_EXAM_SCHEDULE_VIEW:
            return {
                ...state,
                examView: action.payload,
            };
        case FETCH_EXAM_SCHEDULE:
            return {
                ...state,
                fetchexamScheduleList: action.payload,
            };
        case GET_SUB_EXAM_SCHEDULE:
            return {
                ...state,
                subExamScheduleList: action.payload,
            };
        case GET_EXAMTIMETABLE_SECTION:
            return {
                ...state,
                sectionTimeTable: action.payload,
            };
        case GET_TERM_EXAM_TIMETABLE:
            return {
                ...state,
                termExamTimeTable: action.payload,
            };
        case GET_ADMIT_CARD:
            return {
                ...state,
                admitCard: action.payload,
            };
        case GET_DESK_SLIP:
            return {
                ...state,
                deskSlip: action.payload,
            };
        case EXAM_TIMETABLE_POST:
            return {
                ...state,
                ExamTimeTable: [...state.ExamTimeTable, action.payload],
            };
        case GET_EXAM_TIMETABLE_LIST:
            return {
                ...state,
                ExamTimeTable: action.payload,
            };
        case VIEW_EXAM_TIMETABLE_LIST:
            return {
                ...state,
                ExamTimeTableView: action.payload,
            };
        case DELETE_EXAM_TIMETABLE_LIST:
            return {
                ...state,
                ExamTimeTable: state.ExamTimeTable.filter(
                    exam => exam._id !== action.payload
                ),
            };
        case GET_MARK_ALLOCATION_EXAM:
            return {
                ...state,
                markAllocationExam: action.payload,
            };
        case GET_MARK_ALLOCATION_SUB_EXAM:
            return {
                ...state,
                markAllocationSubExam: action.payload,
            };
        case GET_MARK_ALLOCATION_SUBJECT:
            return {
                ...state,
                markAllocationSubject: action.payload,
            };
        case GET_MARK_ALLOCATION_STUDENT:
            return {
                ...state,
                markAllocationStudent: action.payload,
            };
        case CREATE_MARK_ALLOCATION:
            return {
                ...state,
                markAllocation: action.payload,
            };
        case GET_MARKS_CLASS_VISE:
            return {
                ...state,
                markAllocationList: action.payload,
            };
        case POST_MARKS_CLASS_VISE:
            return {
                ...state,
                createMarkNonExam: action.payload
            }
        case GET_NON_EXAM_LIST:
            return {
                ...state,
                nonExamList: action.payload,
            };
        case GET_SUBJECT_NON_EXAM:
            return {
                ...state,
                nonExamSubject: action.payload
            }
        case FETCH_ALL_SUBJECT_MARK_ALLOCATION:
            return {
                ...state,
                allSubjectMarkAllocation: action.payload,
            };
        case GET_MARK_ALLOCATION_TERM:
            return {
                ...state,
                markAllocationTerm: action.payload,
            };
        case FETCH_CLASS_SUBJECT_LIST_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CLASS_SUBJECT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                optionalSubjectData: action.payload.optionalSubjectData,
                uniqueOptionalSubjects: action.payload.uniqueOptionalSubjects,
                uniqueOptionalType: action.payload.uniqueOptionalType,
            };
        case FETCH_CLASS_SUBJECT_LIST_FAILURE:
            return { ...state, loading: false, error: action.error };
        case TEMPLATE_LIST_CLASS:
            return {
                ...state,
                templateListClass: action.payload,
            };
        case TEMPLATE_MAPPING_LIST:
            return {
                ...state,
                templateMappingList: action.payload
            }
        case TEMPLATE_VIEW:
            return {
                ...state,
                templateView: action.payload
            }
        case TEMPLATE_SETTING_LIST:
            return {
                ...state,
                templateSetting: action.payload
            }
        case FETCH_EXAM_REPORT_DATA:
            return {
                ...state,
                examReportData: action.payload
            }
        case GET_EXAM_REPORT_DATA_LIST:
            return {
                ...state,
                examReportList: action.payload
            }
        case GET_TERM_REPORT_DATA_LIST:
            return {
                ...state,
                termReportList: action.payload
            }
        case FETCH_STUDENT_REMARK:
            return {
                ...state,
                studentRemarkList: action.payload
            }
        case GET_MAPPING_STUDENT_REMARK_LIST:
            return {
                ...state,
                studentRemarkListMapping: action.payload
            }
        case TERM_ATTENDANCE_LIST:
            return {
                ...state,
                termAttendanceList: action.payload
            }
        case EXAM_ATTENDANCE_LIST:
            return {
                ...state,
                studentAttendanceList: action.payload
            }
        case EDIT_TERM_ATTENDANCE_LIST:
            return {
                ...state,
                editTermAttendanceList: action.payload
            }
        case MARKSHEET_DATA_LIST:
            return {
                ...state,
                termMarksheetList: action.payload
            }
        case CROSS_LIST_DATA:
            return {
                ...state,
                CrossListData: action.payload
            }
        case GET_SECTION_REPORT_DATA_LIST:
            return {
                ...state,
                sectionReport: action.payload
            }
        case INCOME_CLASS_MAPPING_LISTS:
            return {
                ...state,
                incomeClassSectionList: action.payload
            }
        case INCOME_HEADS_LISTS:
            return {
                ...state,
                incomeHeadList: action.payload
            }
        case INCOME_CLASS_STUDENT:
            return {
                ...state,
                incomeStudentList: action.payload,
            };
        case INCOME_STUDENT_PAYMENT:
            return {
                ...state,
                incomeStudentPayment: action.payload,
            };
        case INCOME_STUDENT_REPORT:
            return {
                ...state,
                incomeStudentReport: action.payload,
            };
            case INCOME_CLASS_SECTION_STUDENT:
                return {
                    ...state,
                    incomeStudentList: action.payload,
                };
        default:
            return state;
    }
};

export default ExamReducer;
